import { Component, OnInit, Output, EventEmitter, Inject, HostListener } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Observable, forkJoin} from "rxjs";
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';

//import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';

import { HttphandlerService } from '../../core/services/httphandler.service';

import { Toast } from '@syncfusion/ej2-notifications';

import Auth from "@aws-amplify/auth";

import { IconoService } from "../../core/services/icono.service"; //Icon del Componente
import { AlertasService } from "../../core/services/alertas.service";
//import { EquipocriticoService } from "../../core/services/equipocritico.service";  Revisar este servicio si no se usa se BORRAR (Solo se usa en este componenete)
import { AlarmasService } from '../../core/services/alarmas.service';
import { LocalService } from '../../core/services/local.service';  //Local Storage

//syncfusion
import { ChangeEventArgs } from '@syncfusion/ej2-angular-dropdowns';

//Upload S3
import { S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand,  } from "@aws-sdk/client-s3";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";



@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  //Upload S3 Config
  urlDownload            : string;
  fileSelecteds3         : any[]  = [];
  fileSelecteds3Grid     : any[]  = [];

  dataBucketObj          : any;

  s3_bucketName          : string = environment.bucketName

  config = {
    bucketName: this.s3_bucketName,
    dirName: 'sinfolder', /* optional - when use: e.g BUCKET_ROOT/dirName/fileName.extesion */
    //keyFileName:'appweb/manuales/Manual-Reliabytics-App.pdf',
    keyFileName:'manuales/Manual-Reliabytics-App.pdf',
    region: 'us-east-1',
    s3Url: ''
  }

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  langValue;

  //quote: Observable<any>;
  nombre;
  configData;
  codEquipoCritico;
  iconItem;

  currentRoute : string;
  userId       : string;
  codUsuario   : string;
  rutaUrl      : string;
  disabNoti    : boolean = false;

  permisoNotif  : boolean = false;
  iniciadoNotif : boolean = false;
  alertas      : any[];
  alarmas      : any[]   = [];
  alarmaslegth : number;
  alertaRecom  : any[];
  dataEquipos  : any[];
  permisosUser : any[]   = [];

  //traslate
  idiomaSelec   : string;
  banderaSelec  : string;
  idiomas       : any[] = [];

  tituloToast   : string;
  tituloInfo    : string;
  tituloInfo2   : string;
  tituloInfo3   : string;
  tituloInfo4   : string;
  tituloInfo5   : string;

  toastObj: Toast = new Toast({
    position: {
        X: 'Right',
        Y: 'Bottom'
    },
    newestOnTop: true,
    showCloseButton: true,
    timeOut: 0,
    animation: {
      hide: { effect: 'SlideRightOut' },
      show: { effect: 'SlideRightIn' }
    },

  });

  @HostListener('window:click',['$event'])
  onClick(event) {
    // event.target.innerWidth;
    // this.resposiveGrid();
    if(this.rutaUrl=="/notificacion"){

      this.getDatosAlarmas();
    }

  }

  constructor(
      @Inject(DOCUMENT) private document: any,
      private router            : Router,
      public languageService    : LanguageService,
      public translate          : TranslateService,
      //public _cookiesService: CookieService,
      private service           : IconoService,
      private alertservice      : AlertasService,
      //private equipoCriService: EquipocriticoService,
      private httpService       : HttphandlerService,
      private alarmasService    : AlarmasService,
      private localService      : LocalService,

  )
  {

    // Se optiene la traduccion de la clave indicada
    this.tranduccion();

    //permite obtener el ususrio logueado
    Auth.currentUserInfo().then((res) => {

    this.userId = res.attributes["email"] ;

    // Valida los permisos del Usuario e inicia el proceso si el usuario tiene los permisos adecuados.
    forkJoin(
      this.getPermisosRol(),
      this.getModulos()
    ).subscribe(
      (res)=>{

        this.ProcPermisosRol(res[0],res[1]);
    });

    });
  }


  listLang = [
  { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
  { text: 'Español', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
  { text: 'Português', flag: 'assets/images/flags/brasil.jpg', lang: 'pt' }
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {

      // Actualiza la traduccion
      this.changeTextTranslate();

      this.toastObj.appendTo('#toast_default');

      this.openMobileMenu = false;
      this.element = document.documentElement;

      this.configData = {
        suppressScrollX: true,
        wheelSpeed: 0.3,
      };

      this.service.customIcon.subscribe(
        (iconComponente) => (this.iconItem = iconComponente)
      );


      this.router.events.subscribe((event: Event) => {

        if (event instanceof NavigationEnd) {
        // console.info( event.url);
          this.rutaUrl = event.url;
          if(event.url=="/notificacion" || event.url.includes("/notificacion;idNotif=")){
            this.disabNoti  = true;
          }else{
            this.disabNoti  = false;
          }
        }

      });

  }

  setLanguage(text: string, lang: string, flag: string) {
      this.countryName = text;
      this.flagvalue = flag;
      //this.cookieValue = lang;
      this.langValue = lang;
      this.languageService.setLanguage(lang);

      //---

      //Se aplica el idioma que viene de la bandera o por defecto
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);

      //Se guarda en la Bd el ultimo idioma seleccionado por el usuario
      this.setIdiomaSelec(this.codUsuario,lang);

      // Se optiene la traduccion de la clave indicada para el titulo del mensaje emergente de  notificaciones
      this.translate.get('TOOBAR.TITULO_NOTIF_4').subscribe((res: string) => {
        this.tituloToast = res;
      });


  }

  /**
  * Toggles the right sidebar
  */
  toggleRightSidebar() {
      this.settingsButtonClicked.emit();
  }

  /**
  * Toggle the menu bar when having mobile screen
  */
  toggleMobileMenu(event: any) {
  event.preventDefault();
  this.mobileMenuButtonClicked.emit();
  }

  /**
  * Logout the user
  */
  logout() {

    this.signOut();

    // Limpia las variables en LocalStorage
    this.localService.clearToken();

    this.router.navigate(["/account/login"]);
  }

  signOut() {
    try {

      Auth.signOut();
      console.log("sessión cognito cerrada");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  /**
  * Fullscreen method
  */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  //Funciones generales

  //GET Data alarmas losEquipos
  public getDatosAlarmas() {

    if(this.permisoNotif){

      if(!this.iniciadoNotif){

        this.iniciadoNotif  = true;
        this.alarmasService.sendMessage({"action": "getAlarmas", "usuario":this.codUsuario});
      }

      this.alarmasService.messages.subscribe(data => {

        if(data.data !== 'Respuesta Pong del WebSocket!'){

          if(typeof data.data === "string"){

            var dta = JSON.parse(data.data);

            if(dta.action === "enviaralarma"){

              this.alarmasService.sendMessage({"action": "getAlarmas", "usuario":this.codUsuario});
            }

          }else{

            var dta = data.data;

            if(typeof dta['data'] != 'undefined' || dta['data'] != null){

              var  alarmas_ = [];

              alarmas_ = dta['data'];

              alarmas_.sort((a, b) => b.id - a.id);

              // alarmas_.forEach((value, index) => {

              //   value.fecha = this.formatFechaDatetime(value.fecha, 'dateTime');

              // } );

              this.alarmas  = alarmas_
              // console.log(this.alarmas);
              // this.alarmas = this.alarmas.map(t => {
              //   const fechaStr = t.fecha; // Asume formato '16/8/2024 15:56:26'
              //   const partes = fechaStr.split(' '); // Separa la fecha y la hora
              //   const fechaPartes = partes[0].split('/'); // Separa el día, mes y año
              //   const horaPartes = partes[1].split(':'); // Separa la hora, minutos y segundos

              //   // Recuerda que los meses en JavaScript empiezan en 0 (enero es 0, diciembre es 11)
              //   const fecha = new Date(
              //     parseInt(fechaPartes[2]), // Año
              //     parseInt(fechaPartes[1]) - 1, // Mes (restar 1)
              //     parseInt(fechaPartes[0]), // Día
              //     parseInt(horaPartes[0]), // Hora
              //     parseInt(horaPartes[1]), // Minutos
              //     parseInt(horaPartes[2]) // Segundos
              //   );

              //   return {
              //     ...t,
              //     fecha
              //   };
              // });
              // console.log(this.alarmas);


              this.alarmaslegth = this.alarmas.length;
            }

            if(dta.mensaje =='Datos actualizados con éxito'){

              this.alarmasService.sendMessage({"action": "getAlarmas", "usuario":this.codUsuario});
            }
          }
        }

      })

    }

  }

  public enviarAlertaNotif(notif: any = null) {

    if(notif!=null){

      this.router.navigate([
        "/notificacion",
        {
          idNotif: notif.id,
        },
      ]);

    }else{

      this.router.navigate(["/notificacion" ]);

    }
  }

  // Ejecuta el Post del aì rest para marcar las alertas como leidas
  public setAlertasLeida(id: number) {

    this.alarmasService.sendMessage({"action": "updateAlarma", "data":{"id":id, "usuario":this.codUsuario}});

    this.alarmas = [];
    this.alarmasService.sendMessage({"action": "getAlarmas", "usuario":this.codUsuario});

  }

  alertSinPrivilegio(text:string) {

    this.alertservice.alertaInformacion(text);
  }

  alertSoprteReliabytics(title, html:string) {


    this.alertservice.soporteReliabytics(title,html);
  }

  alertInfoReliabytics(text:string) {

    const year: number = new Date().getFullYear();
    text=`${text} ©${year}`
    this.alertservice.infoReliabytics(text);
  }

  public ProcPermisosRol(data, modulosApp) {

    let idioma   : string  ="";

    if(data.length>0){
        this.nombre       =  data[0].usuario;
        this.codUsuario   =  data[0].codigo;
        idioma            =  data[0].idioma;
        idioma            =  idioma.toLowerCase();

        for (var prop of data) {
          if(prop.modulo=="Notificaciones"){
              this.permisosUser.push(prop.permiso);
              this.permisoNotif = true;
          }
        }

        //Llamada a la Función que obtinen los datos las alarmas

        this.getDatosAlarmas();

        //Si el usuario no tiene asignado idioma toma el del navegador
        if(idioma==""){
          idioma  = this.translate.getBrowserLang();
          idioma  =  idioma.toLowerCase();
        }

        //Selecciona el idioma del usuario
        this.cambiarIdioma(idioma);

        // Guarda en un local storage los modulos que tiene permiso el usuario
        var modulos: string[]=[];
        var modSinPriv: string[]=[];

        for (var prop of data) {

            var encontrado=modulos.find(mod=>mod==prop["modulo"]);
            if(typeof encontrado=='undefined'){

              modulos.push(prop["modulo"]);

            }
        }

        //Limpia el LcalStorage encriptado
        this.localService.clearJsonValue('modulos');
        this.localService.clearJsonValue('modulosApp');

        //Almacena el LcalStorage encriptado
        this.localService.setJsonValue('modulos', JSON.stringify(modulos));
        this.localService.setJsonValue('modulosApp', JSON.stringify(modulosApp));
    }

  }

  public getPermisosRol() {

    const data$= new Observable(observer=>{

      const url=environment.urlapi.usuario_roles+"roles?correo="+this.userId;

      this.httpService.httpGetData(url)
      .subscribe((data) => {

        observer.next(data["data"]);
        observer.complete()
      },
      (err)=>{

        this.httpErrorRouter(err);
      });
    });

    return data$;

  }

  public getModulos() {

    const data$= new Observable(observer=>{

      const url=environment.urlapi.usuario_roles+"modulos";

      this.httpService.httpGetData(url)
      .subscribe((data) => {

        observer.next(data["data"]);
        observer.complete()
      },
      (err)=>{

        this.httpErrorRouter(err);
      });
    });

    return data$;

  }


  // Función que Muestra los idiomas que soporta la aplicacion
  public cambiarIdioma(idIdioma: string) {


      const val = this.listLang.filter(x => x.lang === idIdioma);
      this.langValue=val;
      this.countryName = val.map(element => element.text);
      if (val.length === 0) {
        if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/spain.jpg'; }
      } else {
        this.flagvalue = val.map(element => element.flag);
      }


      //Se aplica el idioma que viene de la bandera o por defecto
      this.translate.setDefaultLang(idIdioma);
      this.translate.use(idIdioma);

      //Se guarda en la Bd el ultimo idioma seleccionado por el usuario
      //this.setIdiomaSelec(this.codUsuario,idIdioma);

      // Se optiene la traduccion de la clave indicada para el titulo del mensaje emergente de  notificaciones
      this.translate.get('TOOBAR.TITULO_NOTIF_4').subscribe((res: string) => {
      this.tituloToast=res;

      });

  }

  // Ejecuta el Post del aì rest para marcar las alertas como leidas
  public setIdiomaSelec(codigo: string, idioma: string) {

      let codIdioma:string;

      switch (idioma) {
        case 'es':
            codIdioma='01';
            break;
        case 'en':
            codIdioma='02';
            break;
        case 'pt':
            codIdioma='03';
            break;
      };

      let dataIdioma={ idioma:codIdioma };

      let apiURL =environment.urlapi.usuario_roles+"usuario-idioma/" +codigo;
      let paramBody= JSON.stringify(dataIdioma);

      this.httpService.httpPostData(apiURL, paramBody).subscribe(
        {
          next: data => {
            // console.info(data);
          },
          error: error => {console.error('There was an error!:', error);}
        });
  }

  async downLoadManual(){

    const identityPool  = environment.identityPoolId;

    // Set the AWS Region.
    const region = this.config.region; //e.g. "us-east-1"
    const bucketParams = { Bucket: this.config.bucketName, Key: this.config.keyFileName};

     // Set Credential.
    const credenciales = fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: region }),
      identityPoolId: identityPool, //identityPoolId: 'us-east-1:dca5a25e-cdfa-4dcf-af6d-f33241902a8f', // IDENTITY_POOL_ID e.g., eu-west-1:xxxxxx-xxxx-xxxx-xxxx-xxxxxxxxx
    });

    // Create an Amazon S3 service client object.
    const s3Client = new S3Client({
      region: region,
      credentials: credenciales
    });

    const command = new GetObjectCommand(bucketParams);
    this.urlDownload = await getSignedUrl(s3Client, command, { expiresIn: 60 });

    this.openNewWindow(this.urlDownload);
  }

  // Función para Abrir nueva ventana en el navegador
  openNewWindow(url: string): void {

    const link  = this.document.createElement('a');
    link.target = '_blank';
    link.href   = url;
    link.click();
    link.remove();
  }

  //Funcion que permite el enrrutar los errores de http a paginas de salida de errores
  //Debe estar en todos los componentes
  public httpErrorRouter(err:any) {

    if(err.status>=400 && err.status<500){
      this.router.navigate(["/404", {errorhttp: err.error.message, message:err.message, status:err.status }]); //
    }
    if(err.status>=500 && err.status<600){
      this.router.navigate(["/500", {errorhttp: err.error.message, message:err.message, status:err.status }]); //
    }
    if(err.status<400){
      this.router.navigate(["/pagefault", {errorhttp: err.error.message, message:err.message, status:err.status }]); //
    }

  }

  //Funcion changeTextTranslate(): dispara la traduccion justamente cuando ocurre el evento onLangChange
  changeTextTranslate(): void {

    // Se obtiene la traduccion de la clave indicada
    this.translate.onLangChange.subscribe((event) => {

      this.tranduccion();

    })
  }

  //Funcion con Variables a Traducir
  public tranduccion(){

    // Se optiene la traduccion de la clave indicada
    this.translate.get('FOOTER.TITULO_PIE_1').subscribe((res: string) => {
      this.tituloInfo = res;
    });

    this.translate.get('FOOTER.TITULO_PIE_2').subscribe((res: string) => {
      this.tituloInfo2 = res;
    });

    this.translate.get('MAINTENACE.TEXTO_1').subscribe((res: string) => {
      this.tituloInfo3 = res;
    });

    this.translate.get('TOOBAR.TITULO_NOTIF_7').subscribe((res: string) => {
      this.tituloInfo4 = res;
    });

    this.translate.get('TOOBAR.TITULO_NOTIF_8').subscribe((res: string) => {
      this.tituloInfo4 = res;
    });
  }

  irNotificaciones(){
    this.router.navigate(["/configuracionRondas" ]);
  }

  // public formatFechaDatetime(fecha, tipo='date') {
  //   const date = new Date(fecha);

  //   if(tipo === 'date' ){
  //     var dia     = date.getUTCDate();
  //     var mes     = date.getUTCMonth()+1;
  //     var year    = date.getUTCFullYear();


  //     return year.toString() + "-" + mes.toString() + "-" + dia.toString() ;

  //   }else{

  //     var dia     = date.getUTCDate();
  //     var mes     = date.getUTCMonth()+1;
  //     var year    = date.getUTCFullYear();
  //     var hora    = date.getUTCHours();
  //     var hora    = date.getUTCHours();
  //     var min     = date.getUTCMinutes();
  //     var sec     = date.getUTCSeconds();

  //     return year.toString() + "-" + mes.toString() + "-" + dia.toString() + " " + hora.toString()+ ":" +min.toString()+ ":" +sec.toString();
  //   }

  // }


}
